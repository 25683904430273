import React, { useEffect, useState } from "react";
import gsap, { Power2 } from "gsap";
import { Player } from "@lottiefiles/react-lottie-player";

interface Props {
  animationHasPlayed: boolean;
  setAnimationHasPlayed: (state: boolean) => void;
}

const FrontPagesLoader: React.FC<Props> = ({ animationHasPlayed, setAnimationHasPlayed }) => {
  useEffect(() => {
    const tl = gsap.timeline();

    tl.to(".page-loader-inner", { width: "100%", duration: 3.5, ease: Power2.easeInOut });
    tl.to(".colors-squatch", { height: "300px", y: "30vh", duration: 0.7, ease: Power2.easeIn });
    tl.set(".logo-animation", { opacity: 0 });
    tl.to(".colors-squatch", { height: 0, y: "100vh", duration: 0.5, ease: Power2.easeOut }, "<");
    tl.to(
      ".loader-container",
      {
        opacity: 0,
        duration: 0.1,
        ease: Power2.easeOut,
        onComplete: () => {
          setTimeout(() => {
            setAnimationHasPlayed(true);
          }, 100);
        },
      },
      "-=0.2"
    );
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 bg-white bottom-0 right-0 z-[10000] loader-container ${
        animationHasPlayed ? "hidden" : ""
      }`}
    >
      <div className="radial-bg h-full w-full flex items-center justify-center relative">
        <div className="h-0 bg-black w-full absolute top-0 left-0 colors-squatch z-50">
          <div className="h-1/5 w-full bg-primary-500" />
          <div className="h-1/5 w-full bg-accent-yellow-500" />
          <div className="h-1/5 w-full bg-accent-red-500" />
          <div className="h-1/5 w-full bg-accent-green-500" />
          <div className="h-1/5 w-full bg-accent-orange-500" />
        </div>
        <div className="flex flex-col items-center logo-animation w-full -mt-[15%] sm:-mt-[2%]">
          <Player autoplay loop={false} src="/logo-animation.json" className="h-20 sm:w-28" speed={0.5} />
          <div className="h-1 w-50 sm:w-60 bg-grey-border bg-opacity-50 rounded-30 mt-5">
            <div className="h-full bg-primary-500 rounded-30 page-loader-inner w-0"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontPagesLoader;
